import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatCardModule } from '@angular/material/card';

import { PopoverComponent } from './popover.component';
import { PopoverArrowDirective } from './popover-arrow.directive';
import { GenericTextPopover, PopoverDirective } from '@shared/popover/popover.directive';

@NgModule({
    imports: [CommonModule, OverlayModule, PortalModule, MatCardModule],
    declarations: [PopoverComponent, PopoverArrowDirective, PopoverDirective, GenericTextPopover],
    exports: [PopoverDirective]
})
export class PopoverModule {}
