import { Directive, HostBinding, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs';

import { PopoverRef } from './popover.service';

/**
 * Internal directive that shows the popover arrow.
 */
@Directive({
    selector: '[popoverArrow]'
})
export class PopoverArrowDirective {
    @HostBinding('style.width.px')
    @HostBinding('style.height.px')
    arrowSize: number;

    @HostBinding('style.top.px')
    offsetTop: number;

    @HostBinding('style.right.px')
    offsetRight: number;

    @HostBinding('style.bottom.px')
    offsetBottom: number;

    @HostBinding('style.left.px')
    offsetLeft: number;

    private subscription = new Subscription();

    constructor(private popoverRef: PopoverRef, private cd: ChangeDetectorRef) {
        this.arrowSize = this.popoverRef.config.arrowSize;
        this.subscription = this.popoverRef.positionChanges().subscribe(p => {
            const { offsetX, offsetY } = p.connectionPair;

            this.offsetTop = offsetY >= 0 ? offsetY * -1 : null;
            this.offsetBottom = offsetY < 0 ? offsetY : null;
            this.offsetRight = offsetX >= 0 ? offsetX : null;

            if (offsetX) {
                this.offsetLeft = offsetX < 0 ? offsetX * -1 : null;
            } else {
                this.offsetLeft = 110;
            }

            this.cd.detectChanges();
        });
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
